<template>
  <div class="fluid" :key="pageKey" style="background-color: white">
    <h6
      class="indigo--text d-flex justify-center align-center"
      style="font-weight: bold; margin: auto"
    >
      JADWAL SHIFT
      <span>
        <v-tooltip bottom color="grey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              text
              icon
              color="grey darken-2"
              @click="loadPage"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>refresh page</span>
        </v-tooltip></span
      >
      <!-- <v-btn x-small text icon color="grey darken-2" @click="scheduleGrouping1">
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn x-small text icon color="grey darken-2" @click="scheduleGrouping2">
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn> -->
    </h6>

    <!-- <p class="xTitle indigo--text ">
      {{ title }}
    </p> -->
    <div
      v-if="objOfDays !== null"
      class="d-flex justify-center align-start mt-10"
    >
      <div style="width: 80%">
        <div
          class="d-flex justify-content-between align-center"
          style="padding: 0 10px 0 60px; margin-bottom: 10px"
        >
          <div class="d-flex">
            <div style="height: 42px">
              <v-btn
                rounded
                small
                elevation="1"
                color="indigo"
                class="white--text"
                style="font-size: 12px"
                @click.stop="importData"
              >
                Import
                <v-icon size="15" style="margin-left: 5px">
                  mdi-file-import-outline
                </v-icon>
              </v-btn>
            </div>
            <div style="height: 42px; margin-left: 30px">
              <v-text-field
                v-model="paramAPI.year"
                @change="yearWatcher"
                label="Bulan - Tahun"
                type="month"
                dense
              ></v-text-field>
            </div>
            <div
              style="
                width: 280px;
                height: 42px;
                margin-left: 20px;
                margin-top: 2px;
              "
            >
              <v-autocomplete
                v-model="paramAPI.company_id"
                @change="companyWatcher"
                :items="dropdown.company"
                :disabled="getUserProfile.employee.company.plant_id != 6"
                label="Company"
                item-text="name"
                item-value="id"
                return-id
                dense
              ></v-autocomplete>
            </div>
            <!-- <div style="width: 180px; height: 42px; margin-left:10px;">
              <v-autocomplete
                v-model="paramAPI.department_id"
                @change="departmentWatcher"
                :items="dropdown.department"
                label="Department"
                item-text="name"
                item-value="id"
                return-id
                dense
                clearable
              ></v-autocomplete>
            </div> -->
          </div>
          <div style="width: 200px; height: 42px">
            <v-text-field
              v-model="paramAPI.keyword"
              @keyup.enter="searchEnter"
              label="Cari karyawan"
              type="search"
              append-icon="mdi-magnify"
              dense
              clearable
              @click:clear="clearSearch"
            ></v-text-field>
          </div>
        </div>
        <div class="calendar">
          <!-- Employee List -->
          <div
            style="
              position: sticky;
              left: 0;
              z-index: 5;
              background-color: white;
              height: 100%;
            "
          >
            <div
              style="
                padding: 0 0 0 50px;
                position: sticky;
                top: 0;
                z-index: 10;
                background-color: white;
              "
            >
              <div
                class="d-flex justify-center align-center"
                style="width: 150px; height: 60px"
              >
                <p
                  class="xTitle indigo--text"
                  style="font-size: 16px; margin: 0"
                >
                  {{ title }}
                </p>
              </div>
            </div>
            <div
              style="padding: 0 0 0 50px"
              class="d-flex flex-column justify-content-start"
            >
              <!-- <div v-for="(item, index) in groupingResult" :key="index"> -->
              <div v-for="(item, index) in employee" :key="index">
                <div
                  class="d-flex justify-start align-center"
                  style="
                    box-shadow: 2px 0 5px rgba(146, 146, 146, 0.6);
                    width: 150px;
                    height: 70px;
                    background-color: rgba(245, 245, 245, 0.7);
                    border: 1px solid #d6d6d6;
                  "
                >
                  <p
                    style="
                      margin: 0 0 0 5px;
                      color: rgba(54, 25, 25);
                      text-align: left;
                      font-size: 14px;
                      text-transform: uppercase;
                    "
                  >
                    {{ item.name }}
                    <!-- {{ item.employee_name }} -->
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- employee shift data -->
          <div
            style="
              position: relative;
              background-color: white;
              height: 100%;
              z-index: 1;
            "
          >
            <div
              class="d-flex flex-row"
              style="position: sticky; top: 0; z-index: 4"
            >
              <div class="d-flex" v-for="(value, key) in objOfDays" :key="key">
                <div
                  class="board d-flex justify-center align-center"
                  style="height: 60px; border: none"
                >
                  <p
                    style="
                      margin: 0;
                      padding: 2px;
                      width: 50px;
                      text-align: center;
                      color: rgba(54, 25, 25);
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    {{ getDays(key, 'days') }}
                  </p>
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in employee"
              :key="index"
              class="d-flex flex-row"
            >
              <!-- <div
              v-for="(item, index) in groupingResult"
              :key="index"
              class="d-flex flex-row"
            > -->
              <div
                class="d-flex"
                v-for="(xItem, xIndex) in item.schedule"
                :key="xIndex"
              >
                <Board
                  :id="`${xIndex}-${item.nik}`"
                  classes="board"
                  :date="xIndex"
                  :employee="item"
                  :dragoverPrevent="true"
                  :dropEvent="true"
                  @remove="remove"
                  @update="update"
                >
                  <div
                    class="arrdiv d-flex justify-center align-center"
                    v-for="(n, i) in xItem"
                    :key="i"
                    @click="showDetailPresensi(item, n)"
                  >
                    <Card
                      v-if="n !== null"
                      :id="n.shift_id"
                      :shift="convertToString(n.shift_id, n.shift_name)"
                      :draggable="false"
                      :style="`position: relative; cursor: default; background-color:${getBgColor(
                        n.shift_name
                      )}; color:${getPColor(n.shift_name)};`"
                    >
                      <div v-if="n.shift_name !== 'OFF'">
                        <div
                          :style="`${
                            n.attendance.clock_in !== null &&
                            n.attendance.clock_out !== null
                              ? 'position: absolute; top:0; left:2px;'
                              : ''
                          }`"
                        >
                          <p v-if="xItem.length > 1" style="margin: auto">
                            {{
                              n.attendance.clock_in !== null &&
                              n.attendance.clock_out !== null
                                ? n.shift_name.slice(
                                    n.shift_name.lastIndexOf(' ') + 1
                                  )
                                : n.shift_name
                            }}
                          </p>
                          <p v-else style="margin: auto">
                            {{ n.shift_name }}
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <p style="margin: auto">
                          {{ n.shift_name }}
                        </p>
                      </div>

                      <div
                        class="d-flex justify-center align-center"
                        style="
                          position: absolute;
                          top: -1px;
                          right: -1px;
                          z-index: 10;
                          background-color: rgba(255, 255, 255, 0.8);
                          border-radius: 0 0 0 25%;
                        "
                        @click.stop="deleteNode(`${xIndex}-${item.nik}`, n, i)"
                      >
                        <v-icon small color="red" style="cursor: pointer"
                          >mdi-delete-circle</v-icon
                        >
                      </div>
                      <div v-if="n.shift_name !== 'OFF'">
                        <div
                          v-if="
                            n.attendance.clock_in !== null &&
                            n.attendance.clock_out !== null
                          "
                          class="d-flex"
                          style="
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 60%;
                            border-top: 1px solid #9b9a9a;
                            background-color: white;
                          "
                        >
                          <div
                            v-if="xItem.length < 2"
                            class="d-flex flex-column justify-center align-center"
                            style="
                              height: 100%;
                              width: 20px;
                              font-weight: normal;
                              padding: 1px;
                              border-right: 1px solid #9b9a9a;
                              background-color: white;
                            "
                          >
                            <p style="margin: 0; font-size: 10px">in</p>
                            <p style="margin: 0; font-size: 10px">out</p>
                          </div>
                          <div
                            class="d-flex flex-column justify-center align-center"
                            style="
                              height: 100%;
                              font-weight: normal;
                              padding: 1px 2px 1px;
                            "
                          >
                            <p style="margin: 0; font-size: 10px">
                              {{
                                n.attendance.clock_in.slice(
                                  n.attendance.clock_in.indexOf(' ')
                                )
                              }}
                            </p>
                            <p style="margin: 0; font-size: 10px">
                              {{
                                n.attendance.clock_out.slice(
                                  n.attendance.clock_out.indexOf(' ')
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Board>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- master shift -->
      <div style="width: 20%">
        <p
          class="indigo--text"
          style="margin: 0 0 0 15px; font-size: 14px; font-weight: bold"
        >
          DAFTAR SHIFT
        </p>
        <div class="flexbox" style="padding: 0 15px">
          <Board
            id="board-item"
            classes="boardItem"
            :dragoverPrevent="true"
            :dropEvent="false"
          >
            <div v-for="(item, index) in dropdown.shift" :key="index">
              <Card
                v-if="item.name != 'Reguler'"
                :id="item.id"
                :shift="JSON.stringify(item)"
                :draggable="true"
                :style="`background-color:${getBgColor(
                  item.name
                )}; font-size:12px;`"
              >
                <p
                  :style="`margin: auto; color: ${getPColor(
                    item.name
                  )}; font-weight: bold;`"
                >
                  {{ item.name }}
                </p>
              </Card>
            </div>
          </Board>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: absolute;
        top: 0;
        z-index: 3;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-dialog v-model="detailDialog" persistent max-width="700px">
      <v-card v-if="detail !== null" style="position: relative">
        <v-card-title>
          <span class="text-h5 indigo--text" style="margin: 0"
            >DETAIL PRESENSI</span
          >
        </v-card-title>
        <div>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nama Karyawan
                  </p>
                  <v-text-field
                    :value="detail.employee_name"
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    NIK
                  </p>
                  <v-text-field
                    :value="detail.nik"
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>

                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Tanggal
                  </p>
                  <v-text-field
                    :value="detail.date.split('-').reverse().join('-')"
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Jadwal Shift
                  </p>
                  <v-text-field
                    :value="detail.shift_name"
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="5" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Presensi Masuk
                  </p>
                  <v-text-field
                    :value="
                      detail.attendance.clock_in !== null
                        ? detail.attendance.clock_in
                            .slice(
                              0,
                              detail.attendance.clock_in.lastIndexOf(' ')
                            )
                            .split('-')
                            .reverse()
                            .join('-') +
                          ' ' +
                          detail.attendance.clock_in.slice(
                            detail.attendance.clock_in.lastIndexOf(' ') + 1
                          )
                        : ''
                    "
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="5" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Presensi Keluar
                  </p>
                  <v-text-field
                    :value="
                      detail.attendance.clock_out !== null
                        ? detail.attendance.clock_out
                            .slice(
                              0,
                              detail.attendance.clock_out.lastIndexOf(' ')
                            )
                            .split('-')
                            .reverse()
                            .join('-') +
                          ' ' +
                          detail.attendance.clock_out.slice(
                            detail.attendance.clock_out.lastIndexOf(' ') + 1
                          )
                        : ''
                    "
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="2" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Durasi (Jam)
                  </p>
                  <v-text-field
                    :value="getDuration(detail.attendance)"
                    dense
                    readonly
                    outlined
                    style="height: 45px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <!-- <v-card-actions> -->
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            style="position: absolute; top: 5px; right: 5px"
            icon
            color="red"
            text
            @click="closeDetailPresensi"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-card-actions> -->
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar top :color="snackbarType" v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ScheduleImport @loadPage="loadPage" />
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /*  mapActions */ } from 'vuex'
import buildType from '../../../../services/buildType'
import Board from './ScheduleBoard.vue'
import Card from './ScheduleCard.vue'
import ScheduleImport from './Import.vue'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import moment from 'moment'
export default {
  name: 'division',
  components: { Card, Board, ScheduleImport },
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 'all',
      status: '',
      employee_status_id: null,
      sortBy: 'name',
      sortType: 'asc',
      company_id: null,
      department_id: null,
      level_id: null,
      section_id: null,
      itemsPerPage: 10,
      is_shift: true,
      employee_id: null,
      year: ''
      // month: '12'
    },
    totalData: 0,
    employee: [],
    dropdown: {
      shift: [],
      company: [],
      department: []
    },
    loading: false,
    arrOfDays: [],
    objOfDays: null,
    pageKey: 0,
    title: '',
    result: [],
    groupingResult: null,
    detail: null,
    detailDialog: false,

    snackbar: false,
    snackbarText: 'success',
    snackbarType: 'success',
    timeout: 2000,
    scrollX: 0
  }),

  async mounted() {
    console.log('MOUNTED >>>>>>>>>')
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    if (this.getShiftSchedulePageQuery != null) {
      this.paramAPI = this.getShiftSchedulePageQuery
      await this.getTitle(this.paramAPI.year)
    } else {
      await this.getTitle(null)
    }
    setTimeout(async () => {
      // this.paramAPI.department_id = this.getUserProfile.employee.department_id

      await this.getAllDatesInMonthUTC(this.paramAPI.year)
      this.getDropdown()
      // this.getShiftSchedule()
      this.getEmployee()
      const el = document.getElementsByClassName('calendar')

      // console.log('element>>>', el, el)
      el[0].addEventListener('scroll', this.handleScroll)
    }, 200)
  },
  destroyed() {
    console.log('DESTROY >>>>>>>>>')
    const el = document.getElementsByClassName('calendar')

    el[0].removeEventListener('scroll', this.handleScroll)
  },
  created() {
    console.log('CREATED >>>>>>>>>')
  },
  watch: {
    pageKey() {
      const el = document.getElementsByClassName('calendar')
      el[0].removeEventListener('scroll', this.handleScroll)
      setTimeout(() => {
        el[0].addEventListener('scroll', this.handleScroll)
      }, 50)
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getShiftSchedulePageQuery'])
  },
  methods: {
    ...mapMutations(['setShiftSchedulePageQuery']),
    importData() {
      setTimeout(() => {
        document.getElementById('scheduleImport').click()
      }, 200)
    },
    async loadPage() {
      // this.$forceUpdate()
      this.pageKey++
      setTimeout(() => {
        // append last scroll into div calendar
        const lastScroll = localStorage.getItem('schedule_last_scroll')
        document.getElementsByClassName('calendar')[0].scrollLeft = lastScroll
      }, 100)
      setTimeout(async () => {
        this.getTitle(this.paramAPI.year)
        await this.getAllDatesInMonthUTC(this.paramAPI.year)

        // this.getShiftSchedule()
        this.getDropdown()
        this.getEmployee()
      }, 300)
    },
    getDropdown() {
      this.dropdownShift()
      this.dropdownCompany()
      this.dropdownDepartment()
    },
    searchEnter() {
      this.loadPage()
    },
    clearSearch() {
      this.paramAPI.keyword = ''
      this.loadPage()
    },
    yearWatcher() {
      console.log(this.paramAPI.year)
      this.loadPage()
    },
    async companyWatcher(p) {
      this.paramAPI.company_id = p

      this.dropdownDepartment()
      await this.loadPage()
    },
    departmentWatcher() {
      this.loadPage()
    },
    async dropdownShift() {
      let url = `${this.hrsApi}master/universal/shift/dropdown?keyword=`
      const filter = []
      if (this.paramAPI.company_id !== null) {
        filter.push(`{"company_id": ${this.paramAPI.company_id}}`)
      }
      url = url + `&filter=[${filter}]`

      await axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.dropdown.shift = res.data.data
            this.dropdown.shift.push({
              id: 4,
              name: 'OFF'
            })
            return null
          }
          return (this.dropdown.shift = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.shift = [])
        })
    },
    async dropdownCompany() {
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.company = res.data.data)
          }
          return (this.dropdown.company = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownDepartment() {
      console.log('HERE>>>>>>>>>>')
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.paramAPI.company_id !== null) {
        url = url + `?filter=[{"company_id": ${this.paramAPI.company_id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('>>>>>>>>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async getEmployee() {
      let url = `${this.hrsApi}employee/dropdown?mode=&keyword=${this.paramAPI.keyword}&order_by=${this.paramAPI.sortBy}&filter=[{"is_shift":${this.paramAPI.is_shift}}]`
      // url = url + `&order_type=${this.paramAPI.sortType}&offset=${this.paramAPI.offset *
      //   this.paramAPI.limit}&limit=${this.paramAPI.limit}`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      }
      if (this.paramAPI.level_id !== null) {
        url = url + `&level_id=${this.paramAPI.level_id}`
      }

      axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            console.log('xEmployee response 1>>>>', res)
            const response = res.data.data

            console.log('xEmployee before 1>>>>', response)
            for (let i = 0; i < response.length; i++) {
              response[i].schedule = {}

              Object.assign(response[i].schedule, this.objOfDays)
            }

            console.log('xEmployee before 2>>>>', response)
            this.employee = response
            this.getShiftSchedule(response)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getShiftSchedule(result) {
      const xEmployee = result
      const date = this.paramAPI.year
      const year = this.paramAPI.year.slice(0, date.lastIndexOf('-'))
      const month = Number(date.slice(date.lastIndexOf('-') + 1))

      let url = `${this.hrsApi}employee/shift_schedule/list?keyword=${this.paramAPI.keyword}&year=${year}&month=${month}`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.employee_id !== null) {
        url = url + `&employee_id=${this.paramAPI.employee_id}`
      }

      this.setShiftSchedulePageQuery(this.paramAPI)
      axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            console.log('response schedule >>>>', res)
            const response = res.data.data
            // grouping result with nik & employee_id and return object
            // this.groupingResult = this.scheduleGrouping1(response)
            // console.log('this.groupingResult>>>', this.groupingResult)

            console.log('xEmployee before 3 >>>>', xEmployee)
            for (let i = 0; i < response.length; i++) {
              if (response[i].employee_id !== null) {
                const eIndex = xEmployee.findIndex(
                  ({ id, nik, name }) =>
                    id === response[i].employee_id ||
                    nik === response[i].nik ||
                    name === response[i].name
                )
                if (xEmployee[eIndex].schedule[response[i].date] === null) {
                  xEmployee[eIndex].schedule[response[i].date] = []
                }
                xEmployee[eIndex].schedule[response[i].date].push(response[i])
                // xEmployee[eIndex].schedule[response[i].date] = response[i]
              }
            }
            console.log('xEmployee after >>>>', xEmployee)
            this.employee = xEmployee
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async update(xItem) {
      const boardElement = xItem.board
      const boardDate = xItem.date
      const boardEmployee = xItem.employee
      const cardElement = xItem.card
      // remove schedule if schedule exist and card is shift off

      // if (JSON.parse(cardElement.getAttribute('shift')).id === 4) {
      //   if (boardElement.children.length > 0) {
      //     await this.deleteSchedule(boardDate, boardEmployee, boardElement)
      //       .then(data => {
      //         if (data.status_code === '00') {
      //           boardElement.removeChild(boardElement.children[0])
      //           return null
      //         } else {
      //           return null
      //         }
      //       })
      //       .catch(err => {
      //         console.log(err)
      //         return null
      //       })
      //   }
      // }

      // save schedule
      setTimeout(async () => {
        // set last scroll to localstorage
        localStorage.setItem('schedule_last_scroll', this.scrollX)

        // add shift to schedule
        await this.saveSchedule(boardDate, boardEmployee, cardElement)
          .then((data) => {
            if (data.status_code === '00') {
              boardElement.appendChild(cardElement)
              this.showSnackbar(data.status_code, data.status_msg)
              // this.showMsgDialog('success', 'Save success', false)

              return null
            } else {
              // this.showMsgDialog('warning', 'Save Failed', false)
              this.showSnackbar(data.status_code, data.status_msg)
              return null
            }
          })
          .catch((err) => {
            console.log(err)
            this.showSnackbar('-99', 'Save Failed, Please contact an Admin')
            // this.showMsgDialog(
            //   'warning',
            //   'Save Failed, Please contact an Admin',
            //   false
            // )
            return null
          })
        this.loadPage()
      }, 0)
    },
    async deleteNode(id, item, i) {
      const boardElement = document.getElementById(id)
      const boardDate = item.date
      const boardEmployee = {
        id: item.employee_id,
        name: item.employee_name,
        nik: item.nik
      }
      if (boardElement.children.length > 0) {
        await this.deleteSchedule(boardDate, boardEmployee, boardElement, i)
          .then((data) => {
            if (data.status_code === '00') {
              // set last scroll to localstorage
              localStorage.setItem('schedule_last_scroll', this.scrollX)

              // remove node html
              if (i !== undefined) {
                boardElement.removeChild(boardElement.children[i])
              } else {
                boardElement.removeChild(boardElement.children[0])
              }
              this.loadPage()
              return null
            } else {
              return null
            }
          })
          .catch((err) => {
            console.log(err)
            return null
          })
      }
    },
    async remove(xItem) {
      const boardElement = xItem.board
      const boardDate = xItem.date
      const boardEmployee = xItem.employee
      if (boardElement.children.length > 0) {
        await this.deleteSchedule(boardDate, boardEmployee, boardElement)
          .then((data) => {
            if (data.status_code === '00') {
              boardElement.removeChild(boardElement.children[0])
              return null
            } else {
              // this.showMsgDialog('warning', 'Delete Failed', false)
              return null
            }
          })
          .catch((err) => {
            console.log(err)
            // this.showMsgDialog(
            //   'warning',
            //   'Delete Failed, contact an admin',
            //   false
            // )
            return null
          })
      }
    },
    deleteSchedule(date, employee, board, i) {
      const newDate = date.split('/').reverse().join('-')
      let boardChild = board
      console.log('dsadas>>>', boardChild, i)

      if (i !== undefined) {
        boardChild = boardChild.children[i].children[0]
      } else {
        boardChild = boardChild.children[0].children[0]
      }

      const eChildShift = JSON.parse(boardChild.getAttribute('shift'))

      console.log(
        'element',
        newDate,
        employee,
        eChildShift,
        boardChild.children
      )
      var url = `${this.hrsApi}employee/shift_schedule/delete?date=${newDate}&employee_id=${employee.id}&shift_id=${eChildShift.id}`
      return new Promise((resolve, reject) => {
        axios
          .delete(url)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    saveSchedule(date, employee, card) {
      const xDate = date.split('/')
      const newDate = xDate.join('-')
      // const newDate = `${xDate[2]}-${xDate[0]}-${xDate[1]}`
      const eCardShift = JSON.parse(card.getAttribute('shift'))
      console.log('eCardShift >>>>', eCardShift)
      const form = {
        act: 'add',
        employee_id: employee.id,
        shift_id: eCardShift.id,
        date: newDate,
        // is_off: eCardShift.id === 4
        is_off: eCardShift.name === 'OFF'
      }

      console.log('Save Schedule >>>>', form)

      var url = `${this.hrsApi}employee/shift_schedule/save`
      return new Promise((resolve, reject) => {
        axios
          .post(url, form)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    showDetailPresensi(employee, shift) {
      console.log('Detail presensi', employee, shift)
      if (shift !== null) {
        this.detail = shift
        setTimeout(() => {
          this.detailDialog = !this.detailDialog
        }, 300)
      }
    },
    closeDetailPresensi() {
      this.detail = null
      setTimeout(() => {
        this.detailDialog = false
      }, 300)
    },
    scheduleGrouping1(response) {
      const arrRaw = response
      // groupin an array based on nik and remove where object name is null
      const firstGroup = Object.groupBy(arrRaw, ({ nik, employee_id }) =>
        nik !== null ? nik + employee_id : nik
      )
      delete firstGroup.null

      // sort by employee name
      // firstGroup.sort((a, b) => a.employee_name - b.employee_name)

      // looping first group to create new employee properties inside nik object
      const objNik = {}
      for (var key of Object.keys(firstGroup)) {
        objNik[key] = {
          nik: firstGroup[key][0].nik,
          employee_name: firstGroup[key][0].employee_name,
          employee_id: firstGroup[key][0].employee_id,
          schedule: {},
          group: firstGroup[key]
        }

        // add property schedule and assign first group object base on schedule object key (date)
        Object.assign(objNik[key].schedule, this.objOfDays)
        for (let i = 0; i < firstGroup[key].length; i++) {
          objNik[key].schedule[firstGroup[key][i].date] = firstGroup[key][i]
        }
      }
      // this.groupingResult = objNik
      return objNik
    },
    // scheduleGrouping2() {
    //   console.time('grouping2')
    //   const arrRaw = this.result
    //   const notnull = { nullable: false }
    //   const isnull = { nullable: true }

    //   const removedNull = Map.groupBy(arrRaw, ({ nik }) =>
    //     nik !== null ? notnull : isnull
    //   ).get(notnull)

    //   const groupResult = Object.groupBy(removedNull, ({ nik }) => nik)
    //   console.log('arrGroup>>>>', groupResult)
    //   console.timeEnd('grouping2')
    // },
    async getAllDatesInMonthUTC(xYear) {
      console.log('yeaer>>>>', xYear)
      const year = xYear.slice(0, xYear.lastIndexOf('-'))
      const month = Number(xYear.slice(xYear.lastIndexOf('-') + 1)) - 1
      // get all days in selected month & year
      const daysInMonth =
        new Date(Date.UTC(year, month + 1, 1)) -
        new Date(Date.UTC(year, month, 1))
      this.arrOfDays = Array.from(
        { length: daysInMonth / (24 * 60 * 60 * 1000) },
        (_, index) =>
          new Date(Date.UTC(year, month, index + 1)).toLocaleDateString()
      )

      // create object of days with value null
      const objDays = {}
      for (var key of this.arrOfDays) {
        const xDate = key.split('/')
        const newDate = `${xDate[2]}-${
          xDate[0].length > 1 ? xDate[0] : '0' + xDate[0]
        }-${xDate[1].length > 1 ? xDate[1] : '0' + xDate[1]}`

        objDays[newDate] = null
        // objDays[newDate] = []
      }
      console.log('new object>>>>', objDays)
      this.objOfDays = objDays
      return null
    },
    getDays(xDate, xVal) {
      const arrOfdays = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu'
      ]

      const d = new Date(xDate)
      const days = d.getDay()
      const nameOfDays = arrOfdays[days]
      const date = d.getDate()
      switch (xVal) {
        case 'date':
          return `${date}`
        case 'days':
          return `${date < 10 ? `0${date}` : date} ${nameOfDays}`

        default:
          return ''
      }
    },
    convertToString(id, name) {
      const shift = {
        id: id,
        name: name
      }
      return JSON.stringify(shift)
    },
    getTitle(val) {
      const month = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'May',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'October',
        'November',
        'Desember'
      ]
      if (val === null) {
        const date = new Date()
        const thisYear = date.getFullYear()
        const thisMonth = date.getMonth()
        const monthName = month[thisMonth]
        this.title = `${thisYear} ${monthName}`
        const monthNumber = Number(thisMonth) + 1
        const adjustedMonth =
          `${monthNumber}`.length > 1 ? monthNumber : `0${monthNumber}`
        this.paramAPI.year = thisYear + '-' + adjustedMonth
      } else {
        console.log(val)
        const xYear = val.slice(0, val.lastIndexOf('-'))
        const xMonth = Number(val.slice(val.lastIndexOf('-') + 1))
        const monthName = month[xMonth - 1]
        this.title = `${xYear} ${monthName}`
      }
      return null
    },
    getBgColor(val) {
      if (val === 'OFF') {
        // if (Number(val) === 4) {
        return '#ef476f'
      } else {
        return '#06d6a0'
      }
    },
    getPColor(val) {
      if (val === 'OFF') {
        // if (Number(val) === 4) {
        return 'white'
      } else {
        return 'rgba(54, 25, 25)'
      }
    },
    getDuration(time) {
      let duration = null
      if (time !== null) {
        if (time.clock_in !== null && time.clock_out !== null) {
          var timeStart = new Date(time.clock_in).getHours()
          var timeEnd = new Date(time.clock_out).getHours()
          var hourDiff = timeEnd - timeStart
          if (hourDiff < 0) {
            hourDiff = 24 + hourDiff
          }
          duration = hourDiff
        }
      }

      return duration
    },
    handleScroll(event) {
      const el = document.getElementsByClassName('calendar')[0]
      // const scroll = el.scrollL
      const scrollLeft = el.scrollLeft
      this.scrollX = scrollLeft
      console.log('scroll x >>>>>>', scrollLeft)
    },
    showSnackbar(code, msg) {
      let type = ''
      if (code === '00') {
        type = 'success'
      } else {
        type = 'warning'
      }

      this.snackbarText = msg
      this.snackbarType = type
      this.snackbar = true
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, input) {
      const settings = {
        position: 'center',
        icon: pModalType,
        html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
        showConfirmButton: true,
        showCancelButton: pBtnCancel,
        cancelButtonText: 'No'
      }

      if (input !== undefined) {
        if (input) {
          Object.assign(settings, {
            inputPlaceholder: 'Tuliskan keterangan',
            input: 'text'
            // inputAttributes: {
            //   autocapitalize: 'false'
            // }
          })
        }
      }

      return new Promise((resolve) => {
        this.$swal.fire(settings).then((r) => {
          resolve(r)
        })
      })
    }
  }
}
</script>
<style lang="scss">
// #scheduleShift {
//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }

//   body {
//     background-color: grey;
//   }
// }
.xTitle {
  position: relative;
  // top: 10px;
  margin: 5px auto 0;
  width: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}
.calendar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 60vh;
  max-height: 80vh;
  overflow: scroll;
  position: relative;
  overflow-x: scroll;
  padding: 0 5px 5px 0;
  &::-webkit-scrollbar {
    // display: none;
    height: 5px;
    width: 3px;
  }
  &::-webkit-scrollbar-track-piece {
    box-shadow: 0 0 1px rgb(184, 183, 183, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(54, 25, 25, 0.7);
    border-radius: 10px;
  }
}
.flexbox {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  //   max-width: 768px;
  // height: 80vh;
  // overflow-y: scroll;
  // margin: 0 auto;
  // padding: 15px;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.board {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100px;
  position: relative;

  background-color: white;
  border: 1px solid #c9c9c9;
  padding: 10px;
  // box-shadow: inset 2px 2px 7px 1px rgba(146, 146, 146, 0.2);
}

.flexbox .boardItem {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  // flex-direction: column;
  width: 200px;
  background-color: rgba(245, 245, 245, 0.7);
  box-shadow: inset 3px 3px 10px rgba(146, 146, 146, 0.2);
  padding: 15px;
  min-height: 400px;
}

.board .card {
  cursor: pointer;
  // margin-bottom: 15px;
  // background-color: #f3f3f3;
  // padding: 15px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  // color: rgba(54, 25, 25);
  font-weight: bold;
  box-shadow: 2px 1px 7px rgba(109, 109, 109, 0.5);
  border: 1px solid #aaaaaa;
  overflow: hidden;

  // padding: 15px;
}

.board .arrdiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // padding: 15px;
}

.board .arrdiv .card {
  cursor: pointer;
  // margin-bottom: 15px;
  // background-color: #f3f3f3;
  // padding: 15px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  // color: rgba(54, 25, 25);
  font-weight: bold;
  box-shadow: 2px 1px 7px rgba(109, 109, 109, 0.5);
  border: 1px solid #aaaaaa;
  overflow: hidden;

  // padding: 15px;
}
.flexbox .boardItem .card {
  cursor: pointer;
  // background-color: #f3f3f3;
  box-shadow: 2px 1px 7px rgba(109, 109, 109, 0.5);
  margin: 0 2px 5px 2px;
  padding: 5px;
  min-width: 50px;
  border-radius: 20px;
  // height: 60px;
}

// @media (max-width: 768px) {
//   #scheduleShift {
//   }
// }
</style>
